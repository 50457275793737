import React from "react"
import Image from "gatsby-image"
import { FaRegClock } from "react-icons/fa"
import { IoMdArrowRoundForward } from "react-icons/io"
import { Link } from "gatsby"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"

const Project = ({ frontmatter, excerpt, body }) => {
  const { title, image, slug, date, category, readTime } = frontmatter

  return (
    <Wrapper>
      <Image fluid={image.childImageSharp.fluid} className="img" />
      <div className="info">
        {/* <span className="category">{category}</span> */}
        <h3>{title}</h3>
        {/*<p>{excerpt}</p>*/}
        <MDXRenderer>{body}</MDXRenderer>
        {/* <Link to={`/posts/${slug}`} className="link">
          Continue Reading <IoMdArrowRoundForward />
        </Link> */}
        {/* <footer> */}
        {/* <span className="date">
            <FaRegClock className="icon"></FaRegClock>
            {date.split("T")[0]}
          </span> */}
        {/* </footer> */}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.article`
  margin-bottom: 3rem;
  border-bottom: 1px solid var(--clr-grey-9);
  padding-bottom: 3rem;
  .info {
    text-align: center;
  }
  .img {
    margin-bottom: 2rem;
    border-radius: var(--radius);
    height: 17rem;
  }
  .category {
    display: inline-block;
    margin-bottom: 1rem;
    background: var(--clr-grey-10);
    padding: 0.25rem 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: var(--radius);
    letter-spacing: var(--spacing);
    color: var(--clr-grey-5);
  }
  h3 {
    font-weight: 400;
    margin-bottom: 1.5rem;
    text-transform: initial;
    line-height: 1.25;
  }
  .underline {
    width: 5rem;
    height: 1px;
    background: var(--clr-grey-9);
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  p {
    color: var(--clr-grey-5);
    line-height: 1.8;
  }
  .link {
    text-transform: uppercase;
    letter-spacing: var(--spacing);
    font-weight: 700;
    color: var(--clr-primary-5);
    padding-bottom: 0.1rem;
    display: flex;
    align-items: center;
    svg {
      margin-left: 0.25rem;
      font-size: 1.2rem;
    }
  }
  .link:hover {
    border-color: var(--clr-primary-8);
    color: var(--clr-primary-8);
  }
  footer {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid var(--clr-grey-9);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--clr-grey-5);

    & .date {
      display: flex;
      align-items: center;
      & .icon {
        color: var(--clr-primary-5);
        margin-right: 0.5rem;
      }
    }
  }
  table {
    margin-top: 1rem;
    text-align: left;
  }
  th {
    width: 8rem;
    vertical-align: top;
  }
  th > .table-space {
    height: 2.5rem;
  }
  td {
    vertical-align: top;
  }
  .table-space > td,
  .table-space > th {
    padding-bottom: 0.5rem;
  }
  .img {
    height: 100%;
    max-height: 20rem;
  }
  .info {
    text-align: left;
  }
  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2.5rem;
    .underline {
      margin-left: 0;
      margin-right: 0;
    }
    .img {
      height: 100%;
      max-height: 35rem;
    }
  }
`

export default Project
