import React, { useContext } from "react"
import styled from "styled-components"
import Project from "./Project"
import { GatsbyContext } from "../context/context"
import TEXT_MESSAGE from "../constants/text-messages"
// import Banner from "../Banner"
const Projects = ({ projects, title }) => {
  const { language } = useContext(GatsbyContext)
  return (
    <Wrapper className="page section section-center">
      <section className="projects">
        <h3 className="projects-title">
          {" "}
          {language === "ko"
            ? TEXT_MESSAGE.projects_tech.title.ko
            : TEXT_MESSAGE.projects_tech.title.en}
        </h3>
        <ul className="list-container">
          {TEXT_MESSAGE.projects_tech.tech_cats.map(cat => {
            return (
              <li className="list-header">
                {language === "ko" ? cat.title.ko : cat.title.en}
                <ul className="list-content">
                  {cat.li.map(tech => {
                    return <li>{language === "ko" ? tech.ko : tech.en}</li>
                  })}
                </ul>
              </li>
            )
          })}
        </ul>
        <br />
        <br />
        <h3 className="projects-title">{title}</h3>
        <div className="projects-center">
          <article>
            {projects.map(project => {
              return <Project key={project.id} {...project} />
            })}
          </article>
          {/* <article>
          <Banner />
        </article> */}
        </div>
      </section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .projects {
    width: 85vw;
    max-width: var(--max-width);
    margin: 0 auto;
    margin-bottom: 4rem;
  }
  .projects-title {
    font-weight: 700;
    margin-bottom: 2.5rem;
  }
  .list-container {
    font-size: 1.125rem;
    line-height: 1.75;
  }
  .list-header {
    margin-bottom: 1.25rem;
    font-weight: 700;
  }
  .list-content {
    font-weight: 400;
  }
  @media screen and (min-width: 992px) {
    .projects {
      width: 92vw;
    }
  }
  @media screen and (min-width: 1170px) {
    .projects-center {
    }
  }
`

export default Projects
