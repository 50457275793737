import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import Layout from "../components/Layout"
// import Hero from "../components/Hero"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Projects from "../components/Projects"
import { GatsbyContext } from "../context/context"
import TEXT_MESSAGE from "../constants/text-messages"

// ...GatsbyImageSharpFluid
const ProjectsPage = ({ location, data }) => {
  //console.log("ProjectsPage location=", location)
  const { language } = useContext(GatsbyContext)
  const [postLang, setUsePoseLang] = useState(
    language === "ko" ? "post_ko" : "post_en"
  )
  useEffect(() => {
    //console.log("ProjectsPage useEffect language=", language)
    setUsePoseLang(language === "ko" ? "post_ko" : "post_en")
    if (language === "ko") navigate(`/projects`)
  }, [language])

  const {
    [postLang]: { nodes: childMdxes },
  } = data
  const projects = childMdxes.map(child => {
    return child.childMdx
  })
  return (
    <Layout location={location}>
      <SEO title="Projects" description="projects Kim and Hwang" />
      <Projects
        projects={projects}
        title={
          language === "ko"
            ? TEXT_MESSAGE.projects_title.ko
            : TEXT_MESSAGE.projects_title.en
        }
      />
    </Layout>
  )
}

export const query = graphql`
  {
    post_en: allFile(
      filter: {
        sourceInstanceName: { eq: "projects" }
        extension: { eq: "mdx" }
        name: { eq: "post_en" }
      }
      sort: { fields: childMdx___frontmatter___date, order: DESC }
    ) {
      nodes {
        childMdx {
          excerpt
          frontmatter {
            title
            slug
            author
            category
            date
            readTime
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          id
          body
        }
      }
    }
    post_ko: allFile(
      filter: {
        sourceInstanceName: { eq: "projects" }
        extension: { eq: "mdx" }
        name: { eq: "post_ko" }
      }
      sort: { fields: childMdx___frontmatter___date, order: DESC }
    ) {
      nodes {
        childMdx {
          excerpt
          frontmatter {
            title
            slug
            author
            category
            date
            readTime
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          id
          body
        }
      }
    }
  }
`
export default ProjectsPage
